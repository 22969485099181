<template>
  <header ref="header" :class="{ scr: scrFlag, mOpen: mMenu }">
    <div class="inner">
      <a href="#home" v-smooth-scroll class="logo">WIZARDSOFT</a>

      <button class="mMenu" @click="mMenu = !mMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </button>

      <nav>
        <ul>
          <li class="item">
            <a href="#about" v-smooth-scroll @click="tabClick(0)"
              >NFT대행서비스란?</a
            >
          </li>

          <li class="item">
            <a href="#about" v-smooth-scroll @click="tabClick(1)"
              >위즈댑이란?</a
            >
          </li>

          <li class="item">
            <a href="#engineer" v-smooth-scroll @click="mMenu = false"
              >엔지니어</a
            >
          </li>

          <li class="item">
            <a href="#footer" v-smooth-scroll @click="mMenu = false"
              >상담신청</a
            >
          </li>

          <li class="item">
            <a
              href="https://www.wizardsoft.co.kr"
              target="_blank"
              @click="mMenu = false"
              >라이브커머스 서비스</a
            >
          </li>
        </ul>
      </nav>
    </div>

    <transition name="fade">
      <a
        v-if="scrFlag"
        href="#app"
        v-smooth-scroll
        class="btn_goTotop"
        title="최상단으로 이동"
        ><img :src="require('@/assets/img/common/btn_go_to_top.png')" alt=""
      /></a>
    </transition>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'commonHeader',

  data() {
    return {
      scrFlag: false,
      mMenu: false
    }
  },

  computed: {
    ...mapState(['aboutTab'])
  },

  created() {
    document.addEventListener('scroll', this.checkScr)
  },

  mounted() {
    this.checkScr()
  },

  beforeUnmount() {
    document.removeEventListener('scroll', this.checkScr)
  },

  methods: {
    tabClick(num) {
      this.mMenu = false
      this.setAboutTab(num)
    },

    ...mapMutations({
      setAboutTab: 'setAboutTab'
    }),

    checkScr() {
      const scr = window.scrollY
      if (scr > 0) {
        return (this.scrFlag = true)
      }
      return (this.scrFlag = false)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  justify-content: center;
  transition: all 0.6s ease;

  &.scr:not(.mOpen),
  &:hover:not(.mOpen) {
    color: #000;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    a {
      color: #000;

      &:visited,
      &:hover,
      &:focus,
      &:active {
        color: #000;
      }
    }
  }

  .inner {
    display: flex;
    flex: 1 1 auto;
    padding: 0 80px;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      flex: 0 0 auto;
      height: 40px;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: -3px;
      transition: color 0.4s ease;
      color: #fff;

      &:visited,
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }

    nav {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      ul {
        display: flex;
        flex: 1 1 auto;
        li {
          margin-left: 80px;
          font-size: 18px;
          font-weight: 500;

          &:first-child {
            margin-left: 0;
          }

          a {
            position: relative;
            color: #fff;
            transition: color 0.4s ease;

            &:after {
              content: '';
              position: absolute;
              left: 50%;
              bottom: -10px;
              display: block;
              width: calc(100% + 40px);
              height: 3px;
              background: #0050ff;
              transform: translateX(-50%) scale(0);
              transition: transform 0.4s ease;
            }

            &:hover {
              &:after {
                transform: translateX(-50%) scale(1);
              }
            }
            &:visited,
            &:hover,
            &:focus,
            &:active {
              color: #fff;
            }
          }
        }
      }
    }

    .mMenu {
      display: none;
    }
  }

  .btn_goTotop {
    position: fixed;
    right: 100px;
    bottom: 40px;
    z-index: 98;
    display: block;
    width: 40px;
    height: 40px;

    img {
      display: block;
      width: 100%;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

// 모바일
@media screen and (max-width: 1023px) {
  header {
    height: 50px;

    .inner {
      padding: 0 12px;
      .logo {
        position: relative;
        z-index: 100;
        height: 30px;
        font-size: 28px;
      }

      nav {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        align-items: flex-start;
        opacity: 0;
        transform: translateX(100%);
        transition: all 0.6s ease;

        ul {
          margin-top: 100px;
          flex-direction: column;
          padding: 0 30px;
          li {
            margin-left: 0;
            margin-top: 40px;
            font-size: 24px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .mMenu {
        position: relative;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40px;
        height: 40px;
        padding: 8px 5px;

        .bar {
          flex: 0 0 3px;
          width: 100%;
          border-radius: 2px;
          background: #fff;
          transition: all 0.6s ease;
          transform-origin: 50%;
        }
      }
    }

    &.scr:not(.mOpen),
    &:hover:not(.mOpen) {
      .inner {
        .mMenu {
          .bar {
            background: #000;
          }
        }
      }
    }

    &.mOpen {
      .inner {
        nav {
          opacity: 1;
          transform: translateX(0);
        }

        .mMenu {
          .bar:nth-child(1) {
            transform: rotate(45deg) translate(8px, 7px);
          }
          .bar:nth-child(2) {
            opacity: 0;
          }
          .bar:nth-child(3) {
            transform: rotate(-45deg) translate(8px, -7px);
          }
        }
      }
    }

    .btn_goTotop {
      right: 16px;
      bottom: 16px;
    }
  }
}
</style>
