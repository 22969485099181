
exports.ROUTES = {
  mode: 'history',
  base: '/',
  routes: [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "Home" */ '@/pages/Home')
  },
  // {
  //   path: '*',
  //   redirect: '/404'
  // }
  ]
}
