import Vue from 'vue'
import App from './App.vue'
import { store } from './store/index'
import router from './router'
import VueSmoothScroll from 'vue3-smooth-scroll'

Vue.use(VueSmoothScroll);


export const app = new Vue({
  el: '#app',
  store,
  router,
  
  components: { App },
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
