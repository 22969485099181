<template>
  <div id="app">
    <Header />

    <router-view class="container" />

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/common/header.vue'
import Footer from '@/components/common/footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .container {
    flex: 1 1 auto;
  }

  footer {
    flex: 0 1 auto;
  }
}
</style>
