<template>
  <footer id="footer">
    <div class="inner">
      <div class="rowBox">
        <div class="question">
          <button class="request" @click="setCsFormOpen(true)">상담신청</button>

          <p class="reqTitle">문의전화</p>
          <a href="tel:07080654061">070.8065.4061</a>
          <p class="workingTime">평일 AM 09:00 ~ PM 19: 00</p>
          <p class="holiday">토요일, 공휴일 휴무</p>
        </div>

        <div class="info">
          <dl>
            <dt>회사명</dt>
            <dd>{{ CORINFO.COR_NAME }}</dd>
            <br />
            <dt>대표자명</dt>
            <dd>{{ CORINFO.COR_PRESIDENT }}</dd>
            <br />
            <dt>사업자번호</dt>
            <dd>{{ CORINFO.COR_CERT_NUM }}</dd>
            <br />
            <dt>주소</dt>
            <dd>
              <address>{{ CORINFO.COR_ADDRESS }}</address>
            </dd>
            <br />
            <dt>이메일</dt>
            <dd>
              <a :href="`mailto:${CORINFO.CUSTOMER_MAIL}`">{{
                CORINFO.CUSTOMER_MAIL
              }}</a>
            </dd>
            <br />
          </dl>
        </div>
      </div>

      <p class="copyright">
        Copyright 2021. {{ CORINFO.COR_NAME }} Inc. all rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "commonFooter",

  data() {
    return {
      CORINFO: {
        COR_NAME: process.env.VUE_APP_COR_NAME_KO,
        COR_PRESIDENT: process.env.VUE_APP_COR_PRESIDENT,
        COR_CERT_NUM: process.env.VUE_APP_COR_CERT_NUM,
        COR_ADDRESS: process.env.VUE_APP_COR_ADDRESS,
        CUSTOMER_MAIL: process.env.VUE_APP_CUSTOMER_MAIL,
      },
    };
  },

  methods: {
    ...mapMutations({
      setCsFormOpen: "setCsFormOpen",
    }),
  },
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  padding: 50px 0 30px 0;
  background: #00010a;
  color: #fff;
  justify-content: center;

  .inner {
    display: flex;
    flex: 1 1 auto;
    max-width: 1300px;
    flex-direction: column;

    .rowBox {
      overflow: hidden;

      .info {
        float: left;

        dl {
          font-size: 0;
          dt,
          dd {
            display: inline-block;
            font-size: 14px;
            line-height: 1.7;
          }
          dt:after {
            content: ":";
            margin: 0 8px 0 4px;
          }
        }
      }

      .question {
        float: right;
        position: relative;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        text-align: right;

        .request {
          padding: 10px 30px;
          color: #fff;
          font-size: 18px;
          border: 1px solid #ccc;
        }
        .reqTitle {
          margin-top: 40px;
        }
        a {
          font-size: 36px;
          color: inherit;
        }
        .workingTime {
          font-size: 14px;
          color: #999;
        }
        .holiday {
          font-size: 14px;
          color: #999;
        }
      }
    }

    .copyright {
      margin-top: 40px;
    }
  }
}

// 모바일
@media screen and (max-width: 1023px) {
  footer {
    padding: 30px 0;
    .inner {
      position: relative;
      padding: 0 20px;
      box-sizing: border-box;

      .rowBox {
        padding-top: 30px;

        .info {
          float: none;
          margin-top: 60px;
        }

        .question {
          float: none;
        }
      }

      .copyright {
        margin-top: 50px;
      }
    }
  }
}
</style>
