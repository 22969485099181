import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    aboutTab: 0,
    csFormOpen: false,
    privacyPopOpen: false
  },
  
  mutations: {
    setAboutTab (state, payload) {
      state.aboutTab = payload
    },
  
    setCsFormOpen (state, payload) {
      state.csFormOpen = payload
    },
  
    setPrivacyPopOpen (state, payload) {
      state.privacyPopOpen = payload
    }
  }

})
